import React from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import data from '../../data/contact';

const Nav = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={require("../../../images/me_icon.jpg")} alt="" />
      </Link>
      <header>
        <h2>Mridul Agarwal</h2>
        <p><a href="mailto:agarwalmridul09@gmail.com">agarwalmridul09@gmail.com</a></p>
      </header>
    </section>

    <section className="blurb">
      <h2>About</h2>
      <p>Hi, I&apos;m Mridul. I like breaking and building things.
        I studied Computer Science at <a href="https://www.cs.hku.hk/">The University of Hong Kong</a> and graduated in 2021 (fortunately). Pretty sure my life can be summed up by JavaScript, Java, Python, Coffee, Gym, Football and Leo Messi.
        {/* eslint-disable-next-line max-len */}
      </p>
      <ul className="actions">
        <li>
          {window.location.pathname !== `/resume` ? <Link to="/resume" className="button">Learn More</Link> : <Link to="/about" className="button">About Me</Link>}
        </li>
      </ul>
    </section>

    <section id="footer">
      <ul className="icons">
        {data.map((s) => (
          <li key={s.label}>
            <a href={s.link}>
              <FontAwesomeIcon icon={s.icon} />
            </a>
          </li>
        ))}
      </ul>
      <p className="copyright">&copy; Mridul Agarwal <Link to="/about">mridulagarwal.dev</Link>.</p>
    </section>
  </section>
);

export default Nav;
