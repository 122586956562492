import React from 'react';
import {Link} from 'react-router-dom';
import Helmet from 'react-helmet';
import ReactMarkdown from 'react-markdown';

import Main from '../layouts/Main';

import about from "../data/aboutData";
import {initializeReactGAPageView} from "../components/utilityFunctions";

const count = about.split(/\s+/)
  .map((s) => s.replace(/\W/g, ''))
  .filter((s) => s.length).length;

const LinkRenderer = ({ ...children }) => <Link {...children} />;
const About = () => {
    initializeReactGAPageView('/about');

    return (
        <Main>
            <Helmet title="About"/>
            <article className="post" id="about">
                <header>
                    <div className="title">
                        <h2><Link to="/about">About Me</Link></h2>
                        <p>(in about {count} words)</p>
                    </div>
                </header>
                <ReactMarkdown
                    source={about}
                    renderers={{
                        Link: LinkRenderer,
                    }}
                    escapeHtml={false}
                />
            </article>
        </Main>);
};


export default About;
