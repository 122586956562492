import ReactGA from "react-ga";

ReactGA.initialize('UA-148019944-1');

export function initializeReactGAPageView(page) {
    ReactGA.pageview(`/${page}`);
}

export function addEvent(page, action) {
    ReactGA.event({
        category: page,
        action: action
    });
}
