import React from 'react';
import {Link} from 'react-router-dom';

import Main from '../layouts/Main';
import {initializeReactGAPageView} from "../components/utilityFunctions";


const Index = () => {
  initializeReactGAPageView('/index');
  return (
      <Main>
    <article className="post" id="index">
      <header>
        <div className="title">
          <h2>About this site</h2>
        </div>
      </header>
      <p> Welcome to my website. It is a beautifully crafted collection of all my failures that mostly led to something beautiful. Please feel free to read more <Link to="/about">about me</Link>,
        or you can check out my {' '}
        <Link to="/resume">resume</Link>, {' '}
        <Link to="/projects">projects</Link>, {' '}
        or <Link to="/contact">contact</Link> me.
      </p>
    </article>
  </Main>
  )
};

export default Index;
