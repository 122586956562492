import React from 'react';
import {Link} from 'react-router-dom';
import Helmet from 'react-helmet';

import Main from '../layouts/Main';

import Education from '../components/Resume/Education';
import Experience from '../components/Resume/Experience';
import Skills from '../components/Resume/Skills';
import Courses from '../components/Resume/Courses';
import courses from '../data/resume/courses';
import degrees from '../data/resume/degrees';
import positions from '../data/resume/positions';
import {categories, skills} from '../data/resume/skills';
import Competitions from "../components/Resume/Competitions";
import competitions from "../data/resume/competitions";
import {addEvent, initializeReactGAPageView} from "../components/utilityFunctions";
// import References from '../components/Resume/References';

const sections = [
  'Education',
  'Experience',
  'Competitions',
  'Skills',
  'Courses',
];


const Resume = () => {
  initializeReactGAPageView('/resume');

  return (
      <Main>
        <Helmet title="Resume" />
        <article className="post" id="resume">
          <header>
            <div className="title">
              <h2><Link to="resume">Resume</Link></h2>
              <div className="link-container">
                {sections.map((sec) => (
                  <h4 key={sec}>
                    <a onClick={(e)=>addEvent('Resume', `${sec} Clicked`)} href={`#${sec.toLowerCase()}`}>{sec}</a>
                  </h4>))}
              </div>

            </div>
          </header>
          <Education data={degrees}/>
          <Experience data={positions} />
          <Competitions data={competitions}/>
          <Skills skills={skills} categories={categories} />
          <Courses data={courses} />
        </article>
      </Main>
  )
};

export default Resume;
