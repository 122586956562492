const competitions = [
    {
        company: 'CodeIT Suisse 2019',
        position: '3rd Runners Up',
        link: 'https://www.credit-suisse.com/careers/en/career-opportunities/recruitment-technology-microsite/technology-buzz/launch-2019-codeit-suisse.html',
        daterange: '28-29th September 2019',
        points: [
            'Credit Suisse\'s flagship event to hire students who are in their penultimate year of studies.',
            'Questions included a wide variety of topics including but not limited to Graphs, Dynamic Programming, Machine Learning, Mathematical Analysis.',
            'We wrote programs mainly in Java Spring Boot. Python was used for Machine Learning and problems requiring data analysis.',
            'The API end points were exposed for the servers deployed on AWS Elastic Beanstalk (different one for each language).'
        ],
    },
    {
        company: 'SmarTone',
        position: 'Participant',
        link: 'https://www.smartonehackathon.com/',
        daterange: '26-27th October 2019',
        points: [
            'Built a model to detect ingredients in your refrigerator using Google AutoML Object Detection API and Google Vision API.',
            'These detected ingredients were used to suggest recipes.'
        ],
    }, {
        company: 'AXA Hack For Health',
        position: 'Participant',
        link: 'https://www.axa.com.hk/en/hack-for-health-2019',
        daterange: '18-20th October 2019',
        points: [
            'We built an AI Model to detect if you have a respiratory problem by recording your breathing sound.',
            'The sound was converted to an image and then using Keras Functional API it was combined with regular medical data to do predictions.'
        ],
    },
    {
        company: 'PWC Data',
        position: 'Participant',
        link: 'https://www.pwchk.com/en/press-room/press-releases/pr-280119.html',
        daterange: '28-29th January 2019',
        points: [
            'The challenge was to predict vendor payment date.',
            'Used a Random Forest based regressor built over a classifier. The classifier used TF-IDF, SGD Classifier and GridSearchCV.',
            'Later, after the hackathon, we migrated everything to TensorFlow and Keras and made a Flask API.'

        ],
    }, {
        company: 'CSI\'s Young Talent Search',
        position: 'Top 10 National Participant',
        link: 'http://csi-india.org/',
        daterange: '10th September 2016',
        points: [
            'The event is organized by the Computer Society of India.',
            'It is conducted across across various regions in India and top 10 across all the regions are selected for the national round.',
            'We won the regional round and participated in the National Round held in Chennai, India.',
            'At that time all the questions were answered using Java.'
        ],
    }
];

export default competitions;
