
const about = `#### Intro
Football aficionado who often writes code to help people making few things in their life easier.
<br>
On weekdays I am working on writing something in Java, sipping coffee, struggling at classes and trying to make an impact by doing my small bit. On weekends, I can be found on a football field or just at my desk wondering about the piling deadlines. 
<br><br>
#### Some history

- My parents put a computer in my bedroom on 23rd-December-2016 when I was in grade 6. It was a Compaq All in One with Intel Atom which came in with a Huawei modem- which by the way was extremely slow and my biggest life struggle at that time.

- I often switched SIM Cards and played around with 3G/2G Access Points and IPs to get the best out of my modem.

- I was introduced to programming, GW Basic, in Grade 6. I went ahead to learn C++ in Grade 7 and Grade 8, and Java from Grade 9 to Grade 12.
 
- Soon I started 3D Modelling, Video Composting and Video Editing.

- [Blender 3D](https://www.blender.org/), open source 3D software - first software I ever learnt. I used to leave my computer on overnight to render high quality images and then post process them on Adobe PhotoShop.

- In Grade 10, I made animated video on After Effects and Premiere Pro for a dance performance.

- From Grade 10 - Grade 12, along with a team of 13 members, I represented my school at [ComFest](http://comfest.in/) - won 3 individual trophies each year.

- In Grade 12 - along with two team members, I qualified for the national round (consisting on top ten teams across India) of [CSI's Young Talent Search](http://csi-india.org/).

- As a Thank You, I made my school's Android Application.

- For Google Science Fair 2016, I made an android application which paired with a Bluetooth Module. The module was integrated with a pulse detector, on a sudden rise in pulse rate it sensed danger which triggered the SHIELD application and informed a saved contact about your location in every 5 minutes. 

- In 2017, I got into The University of Hong Kong with a Half Tuition Fees Scholarship.
<br><br>
#### I like
- Football
- Basketball
- Summer
- Swimming
- Suits (TV Series)
- Daydreaming
- Running (Not from problems, just you know in general)
- Solo Travelling

#### Travel / Geography

- I am from originally from Kanpur, India. I have since lived in
one city till my high school and moved to Hong Kong for my college.

- I like to solo travel.

- In 2016, I visited: Oxford, UK for my interview with the University of Oxford.

- In 2018, I visited: Barcelona to watch FC Barcelona and Leo Messi live.

- In 2019, I hopefully would visit some countries on my bucket list.

#### Fun facts

- I have a list of thousands of ideas, like ideas which I cannot disclose publicly.
- I almost always have my headphones plugged in.
- I can't remember which keys open which gate of my apartment.
- I also help my dad run family business.
- I added this page because so people can see a different aspect of my life.

#### I dream of

- always finding inspiration.
- enabling a brighter future.
- doing better.
- making my dad proud.
    `;

export default about;
