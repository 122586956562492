const courses = [
  {
    title: 'TensorFlow in Practice Specialization',
    number: '8HC75HARATES',
    link: 'https://www.coursera.org/account/accomplishments/specialization/8HC75HARATES',
    univerity: 'Coursera',
  }, {
    title: 'Learn and Understand NodeJS',
    number: 'UC-U4YQSBHN',
    link: 'http://ude.my/UC-U4YQSBHN',
    univerity: 'Udemy',
  }, {
    title: 'Deep Learning Specialization',
    number: 'KNSE2FFYSVTP',
    link: 'https://www.coursera.org/account/accomplishments/specialization/KNSE2FFYSVTP/',
    univerity: 'Coursera',
  }, {
    title: 'Code Clinic: Python',
    number: '163752-2',
    link: 'http://www.lynda.com/Python-tutorials/Code-Clinic-Python/163752-2.html',
    univerity: 'Lynda',
  }, {
    title: 'Learning Python and Django (2015)',
    number: '386287-2',
    link: 'http://www.lynda.com/Django-tutorials/Up-Running-Python-Django/386287-2.html',
    univerity: 'Lynda',
  }, {
    title: 'Programming Foundations: Real-World Examples',
    number: '418249-2',
    link: 'http://www.lynda.com/Python-tutorials/Programming-Fundamentals-Real-World/418249-2.html',
    univerity: 'Lynda',
  }, {
    title: 'Programming Foundations with JavaScript, HTML and CSS (with Honors)',
    number: 'BBNMR9HLMDHQ',
    link: 'https://www.coursera.org/account/accomplishments/verify/BBNMR9HLMDHQ',
    univerity: 'Coursera',
  },
];

export default courses;
