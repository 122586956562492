import React from 'react';
import './app/static/css/main.scss';
import Index from "./app/views/Index";
import About from "./app/views/About";
import Projects from "./app/views/Projects";
import Contact from "./app/views/Contact";
import Resume from "./app/views/Resume";
import NotFound from "./app/views/NotFound";

import {BrowserRouter as Router, Route, Switch,} from 'react-router-dom';
import {initializeReactGAPageView} from "./app/components/utilityFunctions";


function App() {
    initializeReactGAPageView('/routerRoot');
  return (
      <Router>
        <Switch>
          <Route exact path="/" component={Index} />
          <Route path="/about" component={About} />
          <Route path="/projects" component={Projects} />
          <Route path="/contact" component={Contact} />
          <Route path="/resume" component={Resume} />
          {/* Only useful in development mode */}
          <Route component={NotFound} status={404} />
        </Switch>
      </Router>
  );
}

export default App;
