
const positions = [
  {
    company: 'Bloomberg LP',
    position: 'Site Reliability Engineer',
    link: 'https://www.bloomberg.com/europe',
    daterange: 'June 2021 - Present',
    points: [
      "Developed a machine quality check tool and reduced QC time from 30 minutes per machine to 5 minutes for over 500 machines.",
      "Leading two projects and representing my team in several cross-functional meetings with global SRE and SWE teams.",
      "Automated steps in machine setup pipeline and reduced setup time of an exchange data parser machine from 20 days to 2-3 days.",
      "Actively participated in design discussions and contributed to other monitoring projects by fixing long-standing bugs."
    ],
  },
  {
    company: 'Credit Suisse',
    position: 'Technology Summer Analyst',
    link: 'https://www.credit-suisse.com/',
    daterange: 'July 2020 - August 2020',
    points: [
      "Built a data dictionary platform which will automate information retrieval on a 40 TB data lake and increase productivity by 95%.",
      "Implemented a continuous integration and deployment pipeline using Docker and Kubernetes, which will allow easy and scalable deployment.",
      "Developed a unit tested React.js based webapp, powered by a Kotlin and Hadoop based server."
    ],
  }, {
    company: 'Jump Rope For Good',
    position: 'Software Engineering Intern',
    link: 'https://www.roju.app/',
    daterange: 'February 2020 - March 2020',
    points: [
      "Developed a cross platform mobile app that allows users to record and upload videos, like and comment on news feed posts and make in-app purchases.",
      "Built a server using Spring Frameworks and Java, with PostgreSQL as database, and deployed it on AWS Elastic Beanstalk.",
      "Implemented real-time notifications using Web-Sockets and Database Event Triggers."
    ],
  },{
    company: 'Planto',
    position: 'AI and Software Engineering Intern',
    link: 'https://www.planto.hk/en/',
    daterange: 'December 2018 - January 2020',
    points: [
      "Developed Planto’s Internal Analytics Tool on Flask and Gatsby which allows data aggregation on a database of more than 100,000 users.",
      "Built a Java app to stream read JSON files of size more than 50GB and generate CSVs for Data Analysis.",
      "Designed a Selenium based scraper to extract Hong Kong’s property data",
      "Built a feed forward neural network for property price prediction using Keras with a mean absolute error of USD 40,000.",
      "Migrated Planto’s database from Firebase to PostgreSQL which decreased the database cost by 80%.",
    ],
  }, {
    company: 'Weava',
    position: 'Freelance App Developer',
    link: 'https://www.weavatools.com/',
    daterange: 'December 2018 - May 2019',
    points: [
      'Developed a cross-platform app that allows users to highlight text within the in-app browser and PDF renderer.',
      'Improved users’ efficiency by up to 60% when researching for essays, articles, research papers and exams.',
      'Integrated Stripe Payment API for payments using Apple Pay and Google Pay',
      'Implemented the app using React Native, Redux, Firebase and TypeScript.',
    ],
  }, {
    company: 'Joule',
    position: 'Freelance Developer',
    link: 'https://www.jouleindex.com/',
    daterange: 'September 2018 - December 2018',
    points: [
      'Delivered a cross platform mobile app that helps users buy Joule coins using block-chain',
      'Worked with EOS block chain integrating EOS JS with the application.',
      'Joule was a decentralised application so all data was stored on user\'s local storage. Token generation, private key authentication, private key generation, transaction authentication, transaction initiation was all handled on front end using EOS JS.',
    ],
  }, {
    company: 'AppIt',
    position: 'Software Engineering Intern',
    link: 'http://www.appit.com.hk/',
    daterange: 'June 2018 - August 2018',
    points: [
      'Delivered a cross platform mobile app Marketeer helps users track their diet nutrition',
      'Marketeer was implemented using React Native, Express, NodeJS, PHP YII2, MySQL and MongoDB.',
      'Integrated IBM Watson for image recognition and reduced API cost by 45% by\n using ML Kit.'
    ],
  }, {
    company: 'GoTalents',
    position: 'Front End Developer Intern',
    link: 'https://www.gotalents.net/',
    daterange: 'February 2018 - May 2018',
    points: [
      'Worked with the startup before till their initial launch.',
      'Worked as a front-end engineer, developed dynamic web pages using React JS.',
    ],
  }, {
    company: 'Archiparti',
    position: 'Web Developer Intern',
    link: 'https://archiparti.co/',
    daterange: 'October 2017 - January 2018',
    points: [
      'Worked on various static web pages using HTML, CSS, JavaScript.',
      'Integrated PayPal payment using PHP.',
      'Migrated certain web pages to from HTML/CSS to ReactJS.',
    ],
  },
];

export default positions;
