// TODO Add a couple lines about each project
const data = [
  {
    title: 'G.One',
    subtitle: 'CCHU9056 Virtual Worlds, Real Bodies',
    image: require('../../images/gone.jpeg'),
    date: 'November 2018',
    desc: 'Built for a course. '
      + 'A multilevel game which required puzzle solving. '
      + 'Made from scratch using Unreal Engine.'
  },
  {
    title: 'RKPK',
    subtitle: 'Internal Website for Dad\'s office Utility' ,
    image: require('../../images/rkpk.png'),
    date: 'July 2019 ',
    desc: 'Often when I was busy, my dad called me up to write up some contracts '
      + 'for him. To put an end to this problem and make it convenient for him, '
      + 'I made a simple website for him to generate them on his own.',
  },
  {
    title: 'ShopMart',
    subtitle: 'COMP 3322 Modern Technologies on World Wide Web' ,
    image: require('../../images/web_1.png'),
    date: 'March 2019 ',
    desc: 'A shopping card system created using PHP, HTML, CSS and JS for a course assignment'
  },
  {
    title: 'BigTwo Card Game',
    subtitle: 'COMP 2396 Object-oriented programming and Java' ,
    image: require('../../images/big_two.png'),
    date: 'March 2019 ',
    desc: 'A multi player card game- with chat support- made using Java and Java GUI.'
  }
];

export default data;
