const degrees = [
  {
    school: 'The University of Hong Kong',
    degree: 'Bachelors of Engineering (Computer Science)',
    link: 'https://www.cs.hku.hk',
    year: 2021,
  }, {
    school: 'Dr. Virendra Swarup Education Center (Kidwai Nagar)',
    degree: 'ISC 2017',
    link: 'http://vsec.in/',
    year: 2017,
  },
];

export default degrees;
