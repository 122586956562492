import faGithub from '@fortawesome/fontawesome-free-brands/faGithub';
import faFacebook from '@fortawesome/fontawesome-free-brands/faFacebookF';
import faInstagram from '@fortawesome/fontawesome-free-brands/faInstagram';
import faLinkedinIn from '@fortawesome/fontawesome-free-brands/faLinkedinIn';

const data = [
  {
    link: 'https://github.com/agarwalmridul09/',
    label: 'Github',
    icon: faGithub,
  },
  {
    link: 'https://www.facebook.com/mridul.agarwal09',
    label: 'Facebook',
    icon: faFacebook,
  },
  {
    link: 'https://www.instagram.com/mridul.agarwal09/',
    label: 'Instagram',
    icon: faInstagram,
  },
  {
    link: 'https://www.linkedin.com/in/agarwalmridul09/',
    label: 'LinkedIn',
    icon: faLinkedinIn,
  }
];

export default data;
